import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { palette, textSans17, visuallyHidden, } from '@guardian/source/foundations';
import { useRef, useState } from 'react';
import Dialog from 'components/dialog/dialog';
import Menu, { LinkItem } from 'components/menu/menu';
import SvgDropdownArrow from 'components/svgs/dropdownArrow';
import { countryGroups } from 'helpers/internationalisation/countryGroup';
import { currencies } from 'helpers/internationalisation/currency';
import { sendTrackingEventsOnClick } from 'helpers/productPrice/subscriptions';
const countryGroupSwitcherButton = css `
	appearance: none;
	border: 0;
	padding: 0;
	color: inherit;
	cursor: pointer;
	color: ${palette.neutral[100]};
	${textSans17};

	svg {
		position: absolute;
		margin-top: 0.3125rem;
		margin-left: 0.3125rem;
		path {
			fill: currentColor;
		}
	}

	&:hover {
		color: ${palette.brandAlt[400]};
	}
`;
function CountryGroupSwitcher({ subPath, selectedCountryGroup, countryGroupIds, trackProduct, }) {
    const buttonRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [bounds, setBounds] = useState({
        top: 0,
        left: 0,
    });
    function onCountryGroupSelect(cgId) {
        sendTrackingEventsOnClick({
            id: `toggle_country_${cgId}`,
            ...(trackProduct
                ? {
                    product: trackProduct,
                }
                : {}),
            componentType: 'ACQUISITIONS_OTHER',
        })();
    }
    return (_jsxs("div", { css: css `
				display: inline-flex;
			`, children: [_jsxs("button", { "aria-label": "Select a country", css: countryGroupSwitcherButton, ref: buttonRef, onClick: () => {
                    if (buttonRef.current) {
                        setBounds(buttonRef.current.getBoundingClientRect());
                    }
                    setMenuOpen(true);
                }, children: [countryGroups[selectedCountryGroup].name, ' ', _jsx("strong", { children: currencies[countryGroups[selectedCountryGroup].currency]
                            .extendedGlyph }), _jsx(SvgDropdownArrow, {})] }), _jsx(Dialog, { "aria-label": "Select a country", open: menuOpen, blocking: false, styled: false, closeDialog: () => {
                    setMenuOpen(false);
                }, children: _jsxs(Menu, { style: {
                        top: bounds.top + 30,
                        left: bounds.left,
                        position: 'absolute',
                    }, children: [countryGroupIds.map((countryGroupId) => (_jsxs(LinkItem, { href: `/${countryGroups[countryGroupId].supportInternationalisationId}${subPath}${window.location.search}`, onClick: () => {
                                sendTrackingEventsOnClick({
                                    id: `toggle_country: ${countryGroupId}`,
                                    componentType: 'ACQUISITIONS_BUTTON',
                                })();
                                onCountryGroupSelect(countryGroupId);
                            }, isSelected: countryGroupId === selectedCountryGroup, children: [countryGroups[countryGroupId].name, ' ', currencies[countryGroups[countryGroupId].currency].extendedGlyph] }))), _jsx("button", { css: css `
							${visuallyHidden}
						`, onClick: () => {
                                setMenuOpen(false);
                            }, children: "Close" })] }) })] }));
}
CountryGroupSwitcher.defaultProps = {
    trackProduct: null,
};
export default CountryGroupSwitcher;
