import { productCatalog } from 'helpers/productCatalog';
import { getContributionType } from 'helpers/redux/checkout/product/selectors/productType';
import { isRecurring } from './isContributionRecurring';
export function getLowerBenefitsThreshold(state, regularContributionType, currencyId) {
    const contributionType = regularContributionType ?? getContributionType(state);
    const currency = currencyId ?? state.common.internationalisation.currencyId;
    return getLowerBenefitThreshold(contributionType, currency);
}
export function getLowerBenefitThreshold(contributionType, currencyId) {
    const supporterPlusRatePlan = contributionType === 'ANNUAL' ? 'Annual' : 'Monthly';
    return (productCatalog.SupporterPlus?.ratePlans[supporterPlusRatePlan]?.pricing[currencyId] ?? 0);
}
export function getLowerProductBenefitThreshold(contributionType, currencyId, countryGroupId, product) {
    const ratePlanTier3 = countryGroupId === 'International'
        ? contributionType === 'ANNUAL'
            ? 'RestOfWorldAnnual'
            : 'RestOfWorldMonthly'
        : contributionType === 'ANNUAL'
            ? 'DomesticAnnual'
            : 'DomesticMonthly';
    const ratePlanSupporterPlus = contributionType === 'ANNUAL' ? 'Annual' : 'Monthly';
    return (productCatalog[product]?.ratePlans[product === 'SupporterPlus' ? ratePlanSupporterPlus : ratePlanTier3]?.pricing[currencyId] ?? 0);
}
// Implementation
export function getThresholdPrice(contributionType, state) {
    if (isRecurring(contributionType)) {
        return getLowerBenefitsThreshold(state);
    }
    return;
}
