const Stripe = 'Stripe';
const PayPal = 'PayPal';
const DirectDebit = 'DirectDebit';
const Sepa = 'Sepa';
const None = 'None';
const Success = 'success';
const Pending = 'pending';
export const recaptchaRequiredPaymentMethods = [
    DirectDebit,
    Stripe,
];
const paymentMethods = [Stripe, PayPal, DirectDebit, Sepa, None];
export const isPaymentMethod = (paymentMethod) => {
    return (typeof paymentMethod === 'string' && paymentMethods.includes(paymentMethod));
};
export function toPaymentMethodSwitchNaming(paymentMethod) {
    switch (paymentMethod) {
        case PayPal:
            return 'payPal';
        case Stripe:
            return 'stripe';
        case DirectDebit:
            return 'directDebit';
        case Sepa:
            return 'sepa';
        case None:
            return null;
    }
}
export { Stripe, PayPal, DirectDebit, Sepa };
