import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import 'helpers/types/option';
const dialog = css `
	all: initial;
	position: absolute;
	visibility: hidden;
	height: 0;
	width: 0;
	overflow: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	&::backdrop {
		background: rgba(0, 0, 0, 0);
	}
`;
const openCss = css `
	visibility: visible;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100;
	contain: strict;
	&.component-dialog--styled {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const styledCss = css `
	background: rgba(0, 0, 0, 0.6);
`;
const backdrop = css `
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9;
	height: 100%;
	width: 100%;
`;
function Dialog({ closeDialog, styled = true, open = false, blocking = true, children, }) {
    return (_jsxs("div", { css: [dialog, open && openCss, styled && styledCss], role: "dialog", "data-testid": "dialog", "aria-modal": true, "aria-hidden": !open, tabIndex: -1, onKeyUp: (ev) => {
            if (ev.key === 'Escape') {
                closeDialog();
            }
        }, children: [_jsx("div", { css: css `
					position: relative;
					z-index: 10;
				`, children: children }), _jsx("div", { css: backdrop, "aria-hidden": true, "data-testid": "dialog-backdrop", onClick: () => !blocking && closeDialog() })] }));
}
export default Dialog;
