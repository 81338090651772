import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css, ThemeProvider } from '@emotion/react';
import { from, headlineBold24, palette, space, textSans15, until, } from '@guardian/source/foundations';
import { buttonThemeReaderRevenueBrand, LinkButton, } from '@guardian/source/react-components';
import { useState } from 'react';
import { OtherAmount } from '../../../components/otherAmount/otherAmount';
import { PriceCards } from '../../../components/priceCards/priceCards';
import { trackComponentClick } from '../../../helpers/tracking/behaviour';
import { PaymentCards } from './PaymentIcons';
const sectionStyle = css `
	max-width: 600px;
	margin: auto;
	background-color: ${palette.neutral[100]};
	border-radius: ${space[3]}px;
	padding: 32px;
	${until.desktop} {
		padding-top: ${space[6]}px;
	}
`;
const titleStyle = css `
	margin: 0 0 ${space[2]}px;
	text-align: center;
	${headlineBold24}
	${from.tablet} {
		font-size: 28px;
	}
`;
const standFirstStyle = css `
	color: #606060;
	margin-bottom: ${space[2]}px;
	${from.desktop} {
		margin-bottom: ${space[3]}px;
	}
`;
const btnStyleOverrides = css `
	width: 100%;
	justify-content: center;
	margin-bottom: ${space[3]}px;
	margin-top: ${space[5]}px;
`;
const buttonContainer = css `
	display: flex;
	flex-direction: column;
`;
export function AmountsCard({ countryGroupId, amountsData, currencyId, heading, standFirst, contributionType, }) {
    const [selectedAmount, setSelectedAmount] = useState(amountsData.defaultAmount);
    const [otherAmount, setOtherAmount] = useState('');
    const billingPeriod = contributionType === 'ANNUAL' ? 'Annual' : 'Monthly';
    const amount = selectedAmount === 'other' ? otherAmount : selectedAmount;
    const checkoutLink = contributionType === 'ONE_OFF'
        ? `one-time-checkout?contribution=${amount}`
        : `checkout?product=Contribution&ratePlan=${billingPeriod}&contribution=${amount}`;
    const contributionTypeToPaymentInterval = {
        MONTHLY: 'month',
        ANNUAL: 'year',
    };
    return (_jsxs("section", { css: sectionStyle, children: [_jsxs("div", { css: css `
					${textSans15}
				`, children: [heading && _jsx("h2", { css: titleStyle, children: heading }), standFirst && _jsx("p", { css: standFirstStyle, children: standFirst }), _jsx(PriceCards, { amounts: amountsData.amounts, selectedAmount: selectedAmount, currency: currencyId, paymentInterval: contributionTypeToPaymentInterval[contributionType], onAmountChange: (amount) => {
                            if (amount === 'other') {
                                setSelectedAmount(amount);
                            }
                            else {
                                const amountNumber = parseInt(amount, 10);
                                if (!isNaN(amountNumber)) {
                                    setSelectedAmount(amountNumber);
                                }
                            }
                        }, hideChooseYourAmount: amountsData.hideChooseYourAmount, otherAmountField: _jsx(OtherAmount, { currency: currencyId, selectedAmount: selectedAmount, otherAmount: otherAmount, onOtherAmountChange: (otherAmount) => {
                                setOtherAmount(otherAmount);
                            }, errors: [] }) })] }), _jsxs("div", { css: buttonContainer, children: [_jsx(ThemeProvider, { theme: buttonThemeReaderRevenueBrand, children: _jsx(LinkButton, { href: checkoutLink, cssOverrides: btnStyleOverrides, onClick: () => {
                                trackComponentClick(`npf-contribution-amount-toggle-${countryGroupId}-${contributionType}`);
                            }, children: "Continue to checkout" }) }), _jsx(PaymentCards, {})] })] }));
}
