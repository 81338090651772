import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { cmp } from '@guardian/libs';
import { from, headlineBold24, palette, space, textSans17, } from '@guardian/source/foundations';
import { Container } from '@guardian/source/react-components';
import { FooterLinks, FooterWithContents, } from '@guardian/source-development-kitchen/react-components';
import { useState } from 'preact/hooks';
import { Header } from 'components/headers/simpleHeader/simpleHeader';
import { PageScaffold } from 'components/page/pageScaffold';
import { PaymentFrequencyButtons } from 'components/paymentFrequencyButtons/paymentFrequencyButtons';
import { getAmountsTestVariant } from 'helpers/abTests/abtest';
import { Country } from 'helpers/internationalisation/classes/country';
import { getGeoIdConfig } from 'pages/geoIdConfig';
import { AmountsCard } from '../components/amountsCard';
const recurringContainer = css `
	background-color: ${palette.brand[400]};
	border-bottom: 1px solid ${palette.brand[600]};
	> div {
		padding: ${space[2]}px 10px ${space[4]}px;
	}
	${from.mobileLandscape} {
		> div {
			padding: ${space[2]}px ${space[5]}px ${space[4]}px;
		}
	}
	${from.tablet} {
		border-bottom: none;
		> div {
			padding: ${space[2]}px 10px ${space[4]}px;
		}
	}
	${from.desktop} {
		> div {
			padding: 40px 10px 72px;
		}
	}
`;
const innerContentContainer = css `
	max-width: 940px;
	margin: 0 auto;
	text-align: center;
`;
const heading = css `
	text-align: left;
	color: ${palette.neutral[100]};
	${headlineBold24}
	${from.tablet} {
		text-align: center;
	}
	${from.desktop} {
		font-size: 2.625rem;
	}
`;
const standFirst = css `
	text-align: left;
	color: ${palette.neutral[100]};
	margin: 0 0 ${space[4]}px;
	${textSans17};
	line-height: 1.35;
	strong {
		font-weight: bold;
	}
	${from.tablet} {
		text-align: center;
		width: 65%;
		margin: 0 auto;
	}
	${from.desktop} {
		margin: ${space[4]}px auto ${space[9]}px;
	}
`;
const paymentFrequencyButtons = css `
	margin: ${space[4]}px auto 32px;
	${from.desktop} {
		margin: 0 auto ${space[9]}px;
	}
`;
const disclaimerContainer = css `
	background-color: ${palette.brand[400]};
	> div {
		border-bottom: 1px solid ${palette.brand[600]};
		padding: ${space[4]}px 10px;
	}
	${from.mobileLandscape} {
		> div {
			padding: ${space[5]}px ${space[5]}px;
		}
	}
`;
const links = [
    {
        href: 'https://www.theguardian.com/info/privacy',
        text: 'Privacy policy',
        isExternal: true,
    },
    {
        text: 'Privacy settings',
        onClick: () => {
            cmp.showPrivacyManager();
        },
    },
    {
        href: 'https://www.theguardian.com/help/contact-us',
        text: 'Contact us',
        isExternal: true,
    },
    {
        href: 'https://www.theguardian.com/help',
        text: 'Help centre',
        isExternal: true,
    },
];
const paymentFrequencyMap = {
    ONE_OFF: 'One-time',
    MONTHLY: 'Monthly',
    ANNUAL: 'Annual',
};
export function ContributionsOnlyLanding({ geoId, }) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlSearchParamsRatePlan = urlSearchParams.get('ratePlan');
    const urlSearchParamsOneTime = urlSearchParams.has('oneTime');
    const { currencyKey: currencyId, countryGroupId } = getGeoIdConfig(geoId);
    const countryId = Country.detect();
    const getInitialContributionType = () => {
        if (urlSearchParamsOneTime) {
            return 'ONE_OFF';
        }
        return urlSearchParamsRatePlan === 'Annual' ? 'ANNUAL' : 'MONTHLY';
    };
    const [contributionType, setContributionType] = useState(getInitialContributionType());
    const paymentFrequencies = [
        'ONE_OFF',
        'MONTHLY',
        'ANNUAL',
    ];
    const handlePaymentFrequencyBtnClick = (buttonIndex) => {
        setContributionType(paymentFrequencies[buttonIndex] ?? contributionType);
    };
    const { selectedAmountsVariant: amounts } = getAmountsTestVariant(countryId, countryGroupId, window.guardian.settings);
    return (_jsxs(PageScaffold, { header: _jsx(Header, {}), footer: _jsx(FooterWithContents, { children: _jsx(FooterLinks, { links: links }) }), children: [_jsx(Container, { sideBorders: true, topBorder: true, borderColor: palette.brand[600], cssOverrides: recurringContainer, children: _jsxs("div", { css: innerContentContainer, children: [_jsx("h1", { css: heading, children: "Support fearless, independent journalism" }), _jsxs("p", { css: standFirst, children: ["We're not owned by a billionaire or shareholders - our readers support us. Choose to join with one of the options below.", ' ', _jsx("strong", { children: "Cancel anytime." })] }), _jsx(PaymentFrequencyButtons, { paymentFrequencies: paymentFrequencies.map((paymentFrequency, index) => ({
                                paymentFrequencyLabel: paymentFrequencyMap[paymentFrequency],
                                paymentFrequencyId: paymentFrequency,
                                isPreSelected: paymentFrequencies[index] === contributionType,
                            })), buttonClickHandler: handlePaymentFrequencyBtnClick, additionalStyles: paymentFrequencyButtons }), _jsx(AmountsCard, { amountsData: amounts.amountsCardData[contributionType], countryGroupId: countryGroupId, currencyId: currencyId, contributionType: contributionType })] }) }), _jsx(Container, { sideBorders: true, borderColor: palette.brand[600], cssOverrides: disclaimerContainer })] }));
}
