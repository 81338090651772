import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { from, palette, space, textSans15, textSans17, } from '@guardian/source/foundations';
import { SvgCrossRoundFilled, SvgTickRound, } from '@guardian/source/react-components';
import Tooltip from 'components/tooltip/Tooltip';
import BulletSvg from './bulletSvg';
import { NewBenefitPill } from './newBenefitPill';
const checkListIconCss = (style) => css `
	vertical-align: top;
	padding-right: ${style === 'compact' ? '4px' : '10px'};
	line-height: 0;
`;
const checkListIconColor = (color) => css `
	svg {
		fill: ${color};
	}
`;
const iconContainerCss = css `
	margin-top: -2px;
`;
const checkListTextCss = css `
	& p {
		line-height: 1.35;
	}
`;
const checkListTextItemCss = css `
	& div {
		display: none;

		${from.desktop} {
			display: inline;
			margin-left: 1px;
		}
	}
	strong {
		font-weight: bold;
	}
`;
const listCss = (style) => css `
	${style === 'standard'
    ? css `
				${textSans17};
				line-height: 1.15;
		  `
    : textSans15};

	& li:not(:last-child) {
		border-bottom: 6px solid transparent;
	}

	${from.mobileLandscape} {
		& li:not(:last-child) {
			border-bottom: ${space[3]}px solid transparent;
		}
	}
`;
const opaqueCss = css `
	opacity: 0;
`;
function ChecklistItemIcon({ checked, style, }) {
    const styleSize = style === 'standard' ? 'small' : 'xsmall';
    return style === 'bullet' ? (_jsx(BulletSvg, {})) : checked ? (_jsx(SvgTickRound, { isAnnouncedByScreenReader: true, size: styleSize })) : (_jsx(SvgCrossRoundFilled, { isAnnouncedByScreenReader: true, size: styleSize }));
}
export function BenefitsCheckList({ benefitsCheckListData, style = 'standard', iconColor = style === 'compact' ? palette.success[400] : palette.brand[500], cssOverrides, }) {
    return (_jsx("ul", { css: [listCss(style), cssOverrides], children: benefitsCheckListData.map((item) => (_jsxs("li", { css: css `
						display: flex;
					`, children: [style !== 'hidden' && (_jsx("div", { css: [
                        checkListIconCss(style),
                        checkListIconColor(iconColor),
                        item.maybeGreyedOut,
                    ], children: _jsx("div", { css: [
                            style === 'standard' ? iconContainerCss : css ``,
                            item.hideBullet ? opaqueCss : css ``,
                        ], children: _jsx(ChecklistItemIcon, { checked: item.isChecked, style: style }) }) })), _jsx("div", { css: [checkListTextCss, item.maybeGreyedOut], children: typeof item.text === 'string' ? (_jsxs("span", { css: checkListTextItemCss, children: [item.isNew && (_jsxs(_Fragment, { children: [_jsx(NewBenefitPill, {}), ' '] })), item.strong ? _jsx("strong", { children: item.text }) : item.text, item.toolTip && (_jsx(Tooltip, { children: _jsx("p", { children: item.toolTip }), xAxisOffset: 108, yAxisOffset: 12, placement: "bottom", desktopOnly: true }))] })) : (item.text) })] }))) }));
}
