import { jsx as _jsx } from "@emotion/react/jsx-runtime";
// ----- NOTE ----- //
// This code is designed to work with a single image at one or more crops
// If you want to work with multiple (different) images, maybe try gridPicture instead
// ----- Imports ----- //
import { gridSrcset, gridUrl } from 'helpers/images/theGrid';
import { ascending, classNameWithModifiers } from 'helpers/utilities/utilities';
// ----- Constants ----- //
const MIN_IMG_WIDTH = 300;
// ----- Component ----- //
export default function GridImage(props) {
    if (props.srcSizes.length < 1) {
        return null;
    }
    const sorted = props.srcSizes.sort(ascending);
    const srcSet = gridSrcset(props.gridId, sorted, props.imgType);
    const fallbackSize = sorted.find((_) => _ > MIN_IMG_WIDTH) ?? sorted[0];
    if (fallbackSize === undefined) {
        return null;
    }
    const fallbackSrc = gridUrl(props.gridId, fallbackSize, props.imgType);
    return (_jsx("img", { className: classNameWithModifiers('component-grid-image', props.classModifiers ?? []), sizes: props.sizes, srcSet: srcSet, src: fallbackSrc, alt: props.altText }));
}
// ----- Default Props ----- //
GridImage.defaultProps = {
    imgType: 'jpg',
    altText: '',
    classModifiers: [],
};
