import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { cmp } from '@guardian/libs';
import { from, palette, space, textSans17, until, } from '@guardian/source/foundations';
import { Column, Columns, Hide } from '@guardian/source/react-components';
import { FooterLinks, FooterWithContents, } from '@guardian/source-development-kitchen/react-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckoutHeading } from 'components/checkoutHeading/checkoutHeading';
import { CheckoutHeadingImage } from 'components/checkoutHeading/checkoutHeadingImage';
import CountryGroupSwitcher from 'components/countryGroupSwitcher/countryGroupSwitcher';
import GridImage from 'components/gridImage/gridImage';
import { CountrySwitcherContainer } from 'components/headers/simpleHeader/countrySwitcherContainer';
import { Header } from 'components/headers/simpleHeader/simpleHeader';
import { Container } from 'components/layout/container';
import { LoadingOverlay } from 'components/loadingOverlay/loadingOverlay';
import Nav from 'components/nav/nav';
import { PageScaffold } from 'components/page/pageScaffold';
import { SecureTransactionIndicator } from 'components/secureTransactionIndicator/secureTransactionIndicator';
import HeadlineImageDesktop from 'components/svgs/headlineImageDesktop';
import HeadlineImageMobile from 'components/svgs/headlineImageMobile';
import { isContributionsOnlyCountry } from 'helpers/contributions';
import { AUDCountries, Canada, EURCountries, GBPCountries, International, NZDCountries, UnitedStates, } from 'helpers/internationalisation/countryGroup';
import { useContributionsSelector } from 'helpers/redux/storeHooks';
import { navigateWithPageView } from 'helpers/tracking/trackingOphan';
import HeadlineImagePatronsDesktop from '../../../components/svgs/headlineImagePatronsDesktop';
import HeadlineImagePatronsMobile from '../../../components/svgs/headlineImagePatronsMobile';
import { GuardianTsAndCs } from '../components/guardianTsAndCs';
const checkoutContainer = (isPaymentPage) => {
    let paddingTop = 6;
    if (isPaymentPage) {
        paddingTop = 2;
    }
    return css `
		position: relative;
		color: ${palette.neutral[7]};
		${textSans17};
		padding-top: ${space[paddingTop]}px;
	`;
};
const darkBackgroundContainerMobile = css `
	background-color: ${palette.neutral[97]};
	${until.tablet} {
		background-color: ${palette.brand[400]};
		border-bottom: 1px solid ${palette.brand[600]};
	}
`;
const subHeading = css `
	${textSans17};
	padding-right: ${space[2]}px;
	padding-bottom: 32px;
`;
const secureIndicatorSpacing = css `
	margin-bottom: ${space[3]}px;
	${from.tablet} {
		margin-bottom: ${space[4]}px;
	}
`;
const leftColImageHeader = css `
	${from.desktop} {
		text-align: left;
		padding-bottom: ${space[6]}px;
		img {
			max-width: 100%;
		}
	}
`;
const leftColImage = css `
	text-align: center;
	height: 0px;
	img {
		max-width: 100%;
	}
`;
const boldText = css `
	font-weight: 700;
`;
const links = [
    {
        href: 'https://www.theguardian.com/info/privacy',
        text: 'Privacy policy',
        isExternal: true,
    },
    {
        text: 'Privacy settings',
        onClick: () => {
            cmp.showPrivacyManager();
        },
    },
    {
        href: 'https://www.theguardian.com/help/contact-us',
        text: 'Contact us',
        isExternal: true,
    },
    {
        href: 'https://www.theguardian.com/help',
        text: 'Help centre',
        isExternal: true,
    },
];
export function SupporterPlusCheckoutScaffold({ children, thankYouRoute, isPaymentPage, }) {
    const { countryGroupId } = useContributionsSelector((state) => state.common.internationalisation);
    const { paymentComplete, isWaiting } = useContributionsSelector((state) => state.page.form);
    const { abParticipations, amounts } = useContributionsSelector((state) => state.common);
    const navigate = useNavigate();
    const countrySwitcherProps = {
        countryGroupIds: [
            GBPCountries,
            UnitedStates,
            AUDCountries,
            EURCountries,
            NZDCountries,
            Canada,
            International,
        ],
        selectedCountryGroup: countryGroupId,
        subPath: '/contribute',
    };
    useEffect(() => {
        if (paymentComplete) {
            const urlParams = new URLSearchParams(window.location.search);
            const promoCode = urlParams.get('promoCode');
            const product = urlParams.get('product');
            const thankYouUrlSearchParams = new URLSearchParams();
            if (promoCode) {
                thankYouUrlSearchParams.set('promoCode', promoCode);
            }
            if (product) {
                thankYouUrlSearchParams.set('product', product);
            }
            const thankYouRouteWithSearchParams = `${thankYouRoute}?${thankYouUrlSearchParams.toString()}`;
            navigateWithPageView(navigate, thankYouRouteWithSearchParams, abParticipations, {
                replace: true,
            });
        }
    }, [paymentComplete]);
    const displayPatronsCheckout = !!abParticipations.patronsOneOffOnly;
    const countryIsAffectedByVATStatus = isContributionsOnlyCountry(amounts);
    function SubHeading() {
        if (displayPatronsCheckout) {
            if (countryGroupId === UnitedStates) {
                return (_jsxs("p", { css: subHeading, children: ["You are already among a small and vital number of", ' ', _jsx("span", { css: boldText, children: "people whose support significantly helps to fund our work" }), ". If you can, please consider topping up your support. Thank you."] }));
            }
            else {
                return (_jsxs("p", { css: subHeading, children: ["You are already among a small and vital number of", ' ', _jsx("span", { css: boldText, children: "people whose support disproportionately helps to fund our work" }), ". If you can, please consider topping up your support. Thank you."] }));
            }
        }
        return (_jsx("p", { css: subHeading, children: "As a reader-funded news organisation, we rely on your generosity. Please give what you can, so millions can benefit from quality reporting on the events shaping our world." }));
    }
    return (_jsxs(PageScaffold, { header: _jsxs(_Fragment, { children: [_jsx(Header, { children: !countryIsAffectedByVATStatus && !isPaymentPage && (_jsx(Hide, { from: "desktop", children: _jsx(CountrySwitcherContainer, { children: _jsx(CountryGroupSwitcher, { ...countrySwitcherProps }) }) })) }), !isPaymentPage && (_jsx(Nav, { ...countrySwitcherProps, countryIsAffectedByVATStatus: countryIsAffectedByVATStatus }))] }), footer: _jsx(FooterWithContents, { children: _jsx(FooterLinks, { links: links }) }), children: [isPaymentPage && (_jsx(CheckoutHeading, { withTopBorder: isPaymentPage })), !isPaymentPage && (_jsx(CheckoutHeadingImage, { heading: _jsxs("figure", { css: leftColImageHeader, children: [_jsx(Hide, { from: "desktop", children: displayPatronsCheckout ? (_jsx(HeadlineImagePatronsMobile, {})) : (_jsx(HeadlineImageMobile, {})) }), _jsx(Hide, { until: "desktop", children: displayPatronsCheckout ? (_jsx(HeadlineImagePatronsDesktop, {})) : (_jsx(HeadlineImageDesktop, {})) })] }), image: _jsx("figure", { css: leftColImage, children: _jsx(GridImage, { gridId: "supporterPlusLanding", srcSizes: [817, 408, 204], sizes: "204px", imgType: "png", altText: "" }) }), withTopBorder: isPaymentPage, children: _jsx(SubHeading, {}) })), _jsx(Container, { sideBorders: true, cssOverrides: darkBackgroundContainerMobile, children: _jsxs(Columns, { cssOverrides: checkoutContainer(isPaymentPage), collapseUntil: "tablet", children: [_jsx(Column, { span: [0, 2, 5] }), _jsxs(Column, { span: [1, 8, 7], children: [isPaymentPage && (_jsx(SecureTransactionIndicator, { align: "center", theme: "light", cssOverrides: secureIndicatorSpacing })), children, _jsx(GuardianTsAndCs, { mobileTheme: 'light', displayPatronsCheckout: displayPatronsCheckout, spacing: displayPatronsCheckout ? 'tight' : 'loose' })] })] }) }), isWaiting && (_jsxs(LoadingOverlay, { children: [_jsx("p", { children: "Processing transaction" }), _jsx("p", { children: "Please wait" })] }))] }));
}
