import { combineReducers } from 'redux';
import { directDebitReducer } from './directDebit/reducer';
import { paymentMethodReducer } from './paymentMethod/reducer';
import { paymentRequestButtonReducer } from './paymentRequestButton/reducer';
import { payPalReducer } from './payPal/reducer';
import { sepaReducer } from './sepa/reducer';
import { stripeCardReducer } from './stripe/reducer';
import { stripeAccountDetailsReducer } from './stripeAccountDetails/reducer';
export const paymentReducer = combineReducers({
    paymentMethod: paymentMethodReducer,
    directDebit: directDebitReducer,
    sepa: sepaReducer,
    payPal: payPalReducer,
    stripe: stripeCardReducer,
    stripeAccountDetails: stripeAccountDetailsReducer,
    paymentRequestButton: paymentRequestButtonReducer,
});
