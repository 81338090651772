const usStates = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    AE: 'Armed Forces',
    AA: 'Armed Forces America',
    AP: 'Armed Forces Pacific',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    DC: 'Washington DC (District of Columbia)',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};
const caStates = {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
};
const auStates = {
    ACT: 'Australian Capital Territory',
    NSW: 'New South Wales',
    NT: 'Northern Territory',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    VIC: 'Victoria',
    WA: 'Western Australia',
};
const newspaperCountries = {
    GB: 'United Kingdom',
    IM: 'Isle of Man',
};
export const isoCountries = [
    'GB',
    'US',
    'AU',
    'NZ',
    'CK',
    'CA',
    'AD',
    'AL',
    'AT',
    'BA',
    'BE',
    'BG',
    'BL',
    'CH',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FO',
    'FR',
    'GF',
    'GL',
    'GP',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LI',
    'LT',
    'LU',
    'LV',
    'MC',
    'ME',
    'MF',
    'IS',
    'MQ',
    'MT',
    'NL',
    'NO',
    'PF',
    'PL',
    'PM',
    'PT',
    'RE',
    'RO',
    'RS',
    'SE',
    'SI',
    'SJ',
    'SK',
    'SM',
    'TF',
    'TR',
    'WF',
    'YT',
    'VA',
    'AX',
    'KI',
    'NR',
    'NF',
    'TV',
    'AE',
    'AF',
    'AG',
    'AI',
    'AM',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AW',
    'AZ',
    'BB',
    'BD',
    'BF',
    'BH',
    'BI',
    'BJ',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CC',
    'CD',
    'CF',
    'CG',
    'CI',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'DJ',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EG',
    'EH',
    'ER',
    'ET',
    'FJ',
    'FM',
    'GA',
    'GD',
    'GE',
    'GH',
    'GM',
    'GN',
    'GQ',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HT',
    'ID',
    'IL',
    'IN',
    'IO',
    'IQ',
    'IR',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LK',
    'LR',
    'LS',
    'LY',
    'MA',
    'MD',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MR',
    'MS',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NG',
    'NI',
    'NP',
    'NU',
    'OM',
    'PA',
    'PE',
    'PG',
    'PH',
    'PK',
    'PN',
    'PR',
    'PS',
    'PW',
    'PY',
    'QA',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SG',
    'SL',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TT',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UM',
    'UY',
    'UZ',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WS',
    'YE',
    'ZA',
    'ZM',
    'ZW',
    'FK',
    'GI',
    'GG',
    'IM',
    'JE',
    'SH',
];
const isoCountrySet = new Set(isoCountries);
const countries = {
    GB: 'United Kingdom',
    US: 'United States',
    AU: 'Australia',
    NZ: 'New Zealand',
    CK: 'Cook Islands',
    CA: 'Canada',
    AD: 'Andorra',
    AL: 'Albania',
    AT: 'Austria',
    BA: 'Bosnia-Herzegovina',
    BE: 'Belgium',
    BG: 'Bulgaria',
    BL: 'Saint Barthélemy',
    CH: 'Switzerland',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DK: 'Denmark',
    EE: 'Estonia',
    ES: 'Spain',
    FI: 'Finland',
    FO: 'Faroe Islands',
    FR: 'France',
    GF: 'French Guiana',
    GL: 'Greenland',
    GP: 'Guadeloupe',
    GR: 'Greece',
    HR: 'Croatia',
    HU: 'Hungary',
    IE: 'Ireland',
    IT: 'Italy',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    MC: 'Monaco',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    IS: 'Iceland',
    MQ: 'Martinique',
    MT: 'Malta',
    NL: 'Netherlands',
    NO: 'Norway',
    PF: 'French Polynesia',
    PL: 'Poland',
    PM: 'Saint Pierre & Miquelon',
    PT: 'Portugal',
    RE: 'Réunion',
    RO: 'Romania',
    RS: 'Serbia',
    SE: 'Sweden',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SM: 'San Marino',
    TF: 'French Southern Territories',
    TR: 'Turkey',
    WF: 'Wallis & Futuna',
    YT: 'Mayotte',
    VA: 'Holy See',
    AX: 'Åland Islands',
    KI: 'Kiribati',
    NR: 'Nauru',
    NF: 'Norfolk Island',
    TV: 'Tuvalu',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua & Barbuda',
    AI: 'Anguilla',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BF: 'Burkina Faso',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia',
    BQ: 'Bonaire, Saint Eustatius and Saba',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo (Kinshasa)',
    CF: 'Central African Republic',
    CG: 'Congo (Brazzaville)',
    CI: 'Ivory Coast',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde Islands',
    CW: 'Curaçao',
    CX: 'Christmas Island',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ET: 'Ethiopia',
    FJ: 'Fiji',
    FM: 'Micronesia',
    GA: 'Gabon',
    GD: 'Grenada',
    GE: 'Georgia',
    GH: 'Ghana',
    GM: 'Gambia',
    GN: 'Guinea',
    GQ: 'Equatorial Guinea',
    GS: 'South Georgia & The South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HT: 'Haiti',
    ID: 'Indonesia',
    IL: 'Israel',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KM: 'Comoros',
    KN: 'Saint Christopher & Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LY: 'Libya',
    MA: 'Morocco',
    MD: 'Moldova',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macau',
    MP: 'Northern Mariana Islands',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NP: 'Nepal',
    NU: 'Niue',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PN: 'Pitcairn Islands',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territories',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SG: 'Singapore',
    SL: 'Sierra Leone',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome & Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Swaziland',
    TC: 'Turks & Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'East Timor',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VC: 'Saint Vincent & The Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'United States Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WS: 'Samoa',
    YE: 'Yemen',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    FK: 'Falkland Islands',
    GI: 'Gibraltar',
    GG: 'Guernsey',
    IM: 'Isle of Man',
    JE: 'Jersey',
    SH: 'Saint Helena',
};
export { usStates, caStates, auStates, countries, newspaperCountries, isoCountrySet, };
