import { countryGroups } from 'helpers/internationalisation/countryGroup';
import { currencies, spokenCurrencies, } from 'helpers/internationalisation/currency';
import { Annual, Monthly } from 'helpers/productPrice/billingPeriods';
import { logException } from 'helpers/utilities/logger';
import { roundToDecimalPlaces } from 'helpers/utilities/utilities';
// ----- Types ----- //
export const contributionTypes = ['ONE_OFF', 'MONTHLY', 'ANNUAL'];
export const logInvalidCombination = (contributionType, paymentMethod) => {
    logException(`Invalid combination of contribution type ${contributionType} and payment method ${paymentMethod}`);
};
const getAmount = (selectedAmounts, otherAmounts, contributionType, coverTransactionCostSelected) => {
    const selectedChoiceCardAmount = selectedAmounts[contributionType];
    /**
     * TODO: otherAmount falls back to zero if no other amount
     * entered by user. This prevents the function returning NaN.
     * Ideally it would return undefined and we'd handle that, but the
     * impact of doing so was deemed too great, considering most use cases
     * are in the soon to be deprecated 2-step checkout.
     */
    const otherAmount = otherAmounts[contributionType].amount ?? '0';
    const selectedAmount = selectedChoiceCardAmount === 'other'
        ? parseFloat(otherAmount)
        : selectedChoiceCardAmount;
    // Only cover transaction costs for one off contributions
    const coverTransactionCost = coverTransactionCostSelected && contributionType === 'ONE_OFF';
    return coverTransactionCost
        ? roundToDecimalPlaces(selectedAmount * 1.04)
        : selectedAmount;
};
// ----- Setup ----- //
const numbersInWords = {
    '1': 'one',
    '2': 'two',
    '5': 'five',
    '6': 'six',
    '7': 'seven',
    '10': 'ten',
    '15': 'fifteen',
    '20': 'twenty',
    '25': 'twenty five',
    '30': 'thirty',
    '35': 'thirty five',
    '40': 'forty',
    '50': 'fifty',
    '75': 'seventy five',
    '100': 'one hundred',
    '125': 'one hundred and twenty five',
    '166': 'one hundred and sixty six',
    '200': 'two hundred',
    '250': 'two hundred and fifty',
    '275': 'two hundred and seventy five',
    '500': 'five hundred',
    '750': 'seven hundred and fifty',
    '800': 'eight hundred',
    '2000': 'two thousand',
    '10000': 'ten thousand',
    '16000': 'sixteen thousand',
};
const defaultConfig = {
    ANNUAL: {
        min: 10,
        minInWords: numbersInWords['10'],
        max: 2000,
        maxInWords: numbersInWords['2000'],
        default: 50,
    },
    MONTHLY: {
        min: 2,
        minInWords: numbersInWords['2'],
        max: 166,
        maxInWords: numbersInWords['166'],
        default: 5,
    },
    ONE_OFF: {
        min: 1,
        minInWords: numbersInWords['1'],
        max: 2000,
        maxInWords: numbersInWords['2000'],
        default: 50,
    },
};
const config = {
    GBPCountries: defaultConfig,
    AUDCountries: {
        ANNUAL: defaultConfig.ANNUAL,
        MONTHLY: {
            min: 2,
            minInWords: numbersInWords['2'],
            max: 200,
            maxInWords: numbersInWords['200'],
            default: 20,
        },
        ONE_OFF: {
            min: 1,
            minInWords: numbersInWords['1'],
            max: 16000,
            maxInWords: numbersInWords['16000'],
            default: 50,
        },
    },
    EURCountries: {
        ANNUAL: defaultConfig.ANNUAL,
        MONTHLY: {
            min: 2,
            minInWords: numbersInWords['2'],
            max: 166,
            maxInWords: numbersInWords['166'],
            default: 10,
        },
        ONE_OFF: defaultConfig.ONE_OFF,
    },
    UnitedStates: {
        ANNUAL: {
            ...defaultConfig.ANNUAL,
            max: 10000,
            maxInWords: numbersInWords['10000'],
        },
        MONTHLY: {
            min: 2,
            minInWords: numbersInWords['2'],
            max: 800,
            maxInWords: numbersInWords['800'],
            default: 15,
        },
        ONE_OFF: {
            ...defaultConfig.ONE_OFF,
            max: 10000,
            maxInWords: numbersInWords['10000'],
        },
    },
    International: {
        ANNUAL: defaultConfig.ANNUAL,
        MONTHLY: {
            min: 2,
            minInWords: numbersInWords['2'],
            max: 166,
            maxInWords: numbersInWords['166'],
            default: 10,
        },
        ONE_OFF: defaultConfig.ONE_OFF,
    },
    NZDCountries: {
        ANNUAL: defaultConfig.ANNUAL,
        MONTHLY: {
            min: 2,
            minInWords: numbersInWords['2'],
            max: 200,
            maxInWords: numbersInWords['200'],
            default: 20,
        },
        ONE_OFF: defaultConfig.ONE_OFF,
    },
    Canada: {
        ANNUAL: defaultConfig.ANNUAL,
        MONTHLY: {
            min: 2,
            minInWords: numbersInWords['2'],
            max: 166,
            maxInWords: numbersInWords['166'],
            default: 10,
        },
        ONE_OFF: defaultConfig.ONE_OFF,
    },
};
// ----- Functions ----- //
function getConfigMinAmount(countryGroupId, contribType) {
    if (contribType === 'ANNUAL') {
        switch (countryGroupId) {
            case 'AUDCountries':
            case 'NZDCountries':
                return 75;
            case 'Canada':
                return 60;
            case 'EURCountries':
            case 'International':
            case 'GBPCountries':
            case 'UnitedStates':
                return 50;
        }
    }
    return config[countryGroupId][contribType].min;
}
function validateContribution(input, contributionType, countryGroupId) {
    if (input < config[countryGroupId][contributionType].min) {
        return 'TooLittle';
    }
    else if (input > config[countryGroupId][contributionType].max) {
        return 'TooMuch';
    }
    return null;
}
function parseContribution(input) {
    const amount = Number(input);
    if (input === '' || Number.isNaN(amount)) {
        return {
            error: 'ParseError',
        };
    }
    return {
        valid: true,
        amount: roundToDecimalPlaces(amount),
    };
}
function getMinContribution(contributionType, countryGroupId) {
    return config[countryGroupId][contributionType].min;
}
function toContributionType(s) {
    if (s) {
        switch (s.toUpperCase()) {
            case 'ANNUAL':
                return 'ANNUAL';
            case 'MONTHLY':
                return 'MONTHLY';
            case 'ONE_OFF':
                return 'ONE_OFF';
            case 'SINGLE':
                return 'ONE_OFF';
            default:
                return null;
        }
    }
    return null;
}
function generateContributionTypes(contributionTypes) {
    return {
        GBPCountries: contributionTypes,
        UnitedStates: contributionTypes,
        AUDCountries: contributionTypes,
        EURCountries: contributionTypes,
        NZDCountries: contributionTypes,
        Canada: contributionTypes,
        International: contributionTypes,
    };
}
function parseRegularContributionType(s) {
    if (s === 'ANNUAL') {
        return 'ANNUAL';
    }
    return 'MONTHLY';
}
function billingPeriodFromContrib(contributionType) {
    switch (contributionType) {
        case 'ANNUAL':
            return Annual;
        default:
            return Monthly;
    }
}
function errorMessage(error, contributionType, countryGroupId) {
    const minContrib = config[countryGroupId][contributionType].min;
    const maxContrib = config[countryGroupId][contributionType].max;
    const currency = currencies[countryGroups[countryGroupId].currency];
    switch (error) {
        case 'TooLittle':
            return `Please enter at least ${currency.glyph}${minContrib}`;
        case 'TooMuch':
            return `${currency.glyph}${maxContrib} is the maximum we can accept`;
        case 'ParseError':
            return 'Please enter a numeric amount';
        default:
            return null;
    }
}
function getContributionTypeClassName(contributionType) {
    if (contributionType === 'ONE_OFF') {
        return 'one-off';
    }
    else if (contributionType === 'ANNUAL') {
        return 'annual';
    }
    return 'monthly';
}
function getSpokenType(contributionType) {
    if (contributionType === 'ONE_OFF') {
        return 'single';
    }
    else if (contributionType === 'ANNUAL') {
        return 'annual';
    }
    return 'monthly';
}
function getFrequency(contributionType) {
    if (contributionType === 'ONE_OFF') {
        return '';
    }
    else if (contributionType === 'MONTHLY') {
        return 'per month';
    }
    return 'per year';
}
function getCustomAmountA11yHint(contributionType, countryGroupId) {
    const isoCurrency = countryGroups[countryGroupId].currency;
    let spokenCurrency = spokenCurrencies[isoCurrency].plural;
    if (contributionType === 'ONE_OFF') {
        spokenCurrency = spokenCurrencies[isoCurrency].singular;
    }
    return `Enter an amount of ${config[countryGroupId][contributionType].minInWords}
    ${spokenCurrency} or more for your
    ${getSpokenType(contributionType)} contribution.`;
}
const contributionTypeRadios = [
    {
        value: 'ONE_OFF',
        text: 'Single',
        accessibilityHint: 'Make a single contribution',
        id: 'qa-one-off-toggle',
    },
    {
        value: 'MONTHLY',
        text: 'Monthly',
        accessibilityHint: 'Make a regular monthly contribution',
    },
    {
        value: 'ANNUAL',
        text: 'Annually',
        accessibilityHint: 'Make a regular annual contribution',
    },
];
const contributionTypeAvailable = (contributionType, countryGroupId, contributionTypes) => contributionTypes[countryGroupId].some((settings) => settings.contributionType === contributionType);
const contributionsOnlyAmountsTestName = 'VAT_COMPLIANCE';
const isContributionsOnlyCountry = (amountsVariant) => {
    const { testName } = amountsVariant;
    return testName === contributionsOnlyAmountsTestName;
};
// ----- Exports ----- //
export { config, getConfigMinAmount, toContributionType, generateContributionTypes, validateContribution, parseContribution, getMinContribution, billingPeriodFromContrib, errorMessage, getContributionTypeClassName, getSpokenType, getFrequency, getCustomAmountA11yHint, contributionTypeRadios, parseRegularContributionType, getAmount, contributionTypeAvailable, contributionsOnlyAmountsTestName, isContributionsOnlyCountry, };
