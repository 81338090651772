import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import { palette } from '@guardian/source/foundations';
import { filterBenefitByRegion } from 'helpers/productCatalog';
const greyedOut = css `
	color: ${palette.neutral[60]};

	svg {
		fill: ${palette.neutral[60]};
	}
`;
const boldText = css `
	font-weight: bold;
`;
export const checkListData = ({ higherTier, countryGroupId, }) => {
    const maybeGreyedOutHigherTier = higherTier ? undefined : greyedOut;
    const higherTierItems = [
        {
            isChecked: higherTier,
            text: (_jsxs("p", { children: [_jsx("span", { css: boldText, children: "Full access to our news app. " }), "Read our reporting on the go"] })),
            maybeGreyedOut: maybeGreyedOutHigherTier,
        },
        {
            isChecked: higherTier,
            text: (_jsxs("p", { children: [_jsx("span", { css: boldText, children: "Ad-free reading. " }), "Avoid ads on all your devices"] })),
            maybeGreyedOut: maybeGreyedOutHigherTier,
        },
        {
            isChecked: higherTier,
            text: (_jsxs("p", { children: [_jsx("span", { css: boldText, children: "Uninterrupted reading. " }), " See far fewer asks for support"] })),
            maybeGreyedOut: maybeGreyedOutHigherTier,
        },
        {
            isChecked: higherTier,
            text: (_jsxs("p", { children: [_jsx("span", { css: boldText, children: "The Guardian Feast App. " }), " Unlimited access to the ultimate recipe app"] })),
            maybeGreyedOut: maybeGreyedOutHigherTier,
        },
        {
            isChecked: higherTier,
            text: (_jsxs("p", { children: [_jsx("span", { css: boldText, children: "Exclusive access" }), " to partner offers"] })),
            maybeGreyedOut: maybeGreyedOutHigherTier,
            specificToRegions: ['AUDCountries'],
        },
    ];
    return [
        {
            isChecked: true,
            text: (_jsxs("p", { children: [_jsx("span", { css: boldText, children: "A regular supporter newsletter. " }), "Get exclusive insight from our newsroom"] })),
        },
        ...higherTierItems.filter((checkListItem) => filterBenefitByRegion(checkListItem, countryGroupId)),
    ];
};
