import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import { getRecurringContributorStatus } from './thunks';
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsSignedIn(state, action) {
            state.isSignedIn = action.payload;
        },
        setTestUserStatus(state, action) {
            state.isTestUser = action.payload.isTestUser ?? false;
            state.isPostDeploymentTestUser =
                action.payload.isPostDeploymentTestUser ?? false;
        },
        setIsReturningContributor(state, action) {
            state.isReturningContributor = action.payload;
        },
        setStorybookUser(state, action) {
            state.isStorybookUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRecurringContributorStatus.fulfilled, (state, action) => {
            state.supporterStatus = action.payload;
        });
    },
});
export const userReducer = userSlice.reducer;
