import { config, contributionTypes } from 'helpers/contributions';
import { DigitalPack, GuardianWeekly, Paper, PaperAndDigital, } from 'helpers/productPrice/subscriptions';
import { getDefaultContributionType } from 'helpers/redux/commonState/selectors';
export function isContribution(product) {
    return contributionTypes.includes(product);
}
export function getContributionType(state) {
    const { productType } = state.page.checkoutForm.product;
    if (isContribution(productType)) {
        return productType;
    }
    return getDefaultContributionType(state);
}
function isSubscription(product) {
    return [DigitalPack, PaperAndDigital, Paper, GuardianWeekly].includes(product);
}
function getSubscriptionTypeFromURL() {
    const urlPathRegex = /\/subscribe\/(.+)\/checkout/;
    const productsToUrlPath = {
        digital: DigitalPack,
        paper: Paper,
        weekly: GuardianWeekly,
    };
    const [, match] = urlPathRegex.exec(window.location.pathname) ?? [];
    if (match) {
        const product = productsToUrlPath[match];
        if (product) {
            return product;
        }
    }
    return DigitalPack;
}
export function getSubscriptionType(state) {
    const { productType } = state.page.checkoutForm.product;
    if (isSubscription(productType)) {
        return productType;
    }
    return getSubscriptionTypeFromURL();
}
export function getSelectedAmount(selectedAmounts, contributionType, defaultAmount) {
    return selectedAmounts[contributionType] || defaultAmount;
}
export function getMinimumContributionAmount(contributionType) {
    return (state) => {
        const { countryGroupId } = state.common.internationalisation;
        const { min } = config[countryGroupId][contributionType ?? getContributionType(state)];
        return min;
    };
}
export function getMaximumContributionAmount(contributionType) {
    return (state) => {
        const { countryGroupId } = state.common.internationalisation;
        const { max } = config[countryGroupId][contributionType ?? getContributionType(state)];
        return max;
    };
}
