import { isSwitchOn } from 'helpers/globalsAndSwitches/globals';
export function countdownSwitchOn() {
    const isOn = isSwitchOn('featureSwitches.enableCampaignCountdown');
    return isOn;
}
const campaigns = {};
const forceCampaign = (campaignId) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('forceCampaign') === campaignId;
};
export function getCampaignSettings(countryGroupId, promoCode) {
    for (const campaignId in campaigns) {
        const campaign = campaigns[campaignId];
        if (campaign) {
            const isEligible = isCampaignEnabled(campaignId) &&
                campaign.isEligible(countryGroupId, promoCode);
            if (isEligible || forceCampaign(campaignId)) {
                return campaign;
            }
        }
    }
    return null;
}
function isCampaignEnabled(campaignId) {
    const { campaignSwitches } = window.guardian.settings.switches;
    return (window.location.hash ===
        `#settings.switches.campaignSwitches.${campaignId}=On` ||
        campaignSwitches[campaignId] === 'On');
}
